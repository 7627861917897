import {combineReducers, createStore, applyMiddleware} from "redux";
import { reducers } from "./reducers";
import thunk from 'redux-thunk';

let middleware = [thunk]
// export const store = createStore (
//     reducers, 
//     {}, 
//     window.__REDUX_DEVTOOLS_EXTENSION___ && window.__REDUX_DEVTOOLS_EXTENSION___(),
//     applyMiddleware(...middleware)
// )

export default function configureStore() {
  const store = createStore(
    combineReducers({
      ...reducers,
    }),
    applyMiddleware(...middleware)
  );

  return store;
}