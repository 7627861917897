export const actionTypes = {
    CONFIG_REQUEST : "CONFIG_REQUEST",
    GET_CONFIG_SUCCESS : "GET_CONFIG_SUCCESS",
    CONFIG_FAILURE: "CONFIG_FAILURE",


    GET_ALL_BANK_REQUEST : "GET_ALL_BANK_REQUEST",
    GET_ALL_BANK_SUCCESS : "GET_ALL_BANK_SUCCESS",
    GET_ALL_BANK_FAILURE: "GET_ALL_BANK_FAILURE",


    LOGIN_REQUEST : "LOGIN_REQUEST",
    LOGIN_SUCCESS : "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
}