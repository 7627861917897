import React, { useState } from 'react'
import { Toaster } from 'react-hot-toast'
import ActiveBankModal from './activeBank'
import BanksModal from './banks'
import PrivacyModal from './privacy'

export default function ParentModal(props) {
    const [appPage, setAppPage] = useState(1)
    const [bankInfo, setBankInfo] = useState({})
    
    let changeAppPage = (page) => {
        setAppPage(page)
    }

    let pushInfo = (info) => {
        setBankInfo(info)
    }

    let closeComponent = () => {

        // dispatch success event for parent element
    
        var event_response = {
    
          eventInstance: 'identity_bank_event',
    
          data: {
    
            status: 'failed',
    
            message: 'Bank Statement Cancelled'
    
          }
    
        }
    
        window.parent.postMessage(event_response, '*')
    
      }

    return (
        <div>
            <Toaster position='top-right' />
            {props.openComponent &&
                <div className="main-modal">
                    {appPage === 1 &&
                        <PrivacyModal setOpenComponent={props.setOpenComponent} changePage={changeAppPage} closeComponent={closeComponent}/>
                    }
                    {appPage === 2 &&
                        <BanksModal setOpenComponent={props.setOpenComponent} changePage={changeAppPage} pushBankInfo={pushInfo} closeComponent={closeComponent} />
                    }
                    {appPage === 3 &&
                        <ActiveBankModal setOpenComponent={props.setOpenComponent} changePage={changeAppPage} bankInfo={bankInfo} closeComponent={closeComponent}/>
                    }
                </div>
            }
        </div>
    )
}
