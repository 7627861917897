import React from 'react'

export default function FooterComp() {
    return (
        <div className="text-center">
            <div className="footer-area">
                <p>Powered by Prembly</p>
            </div>
        </div>
    )
}
