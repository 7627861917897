import emptyImg from '../../assets/404-img.png'

export default function InvalidPage() {
    return (
        <div className='errorpage' >

            <div className='col-md-4 mx-auto text-center my-5'>
                <img src={emptyImg} className="w-100" alt="" />
                <h3>Oops</h3>
                <h5>
                    You are seeing this page because, you have either passed an invalid token, 
                    or you entered an invalid url.
                </h5>
            </div>
        </div>
    )
}
