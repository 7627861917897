
export function lightenDarkenColor(col, amt) {
  var usePound = false

  if (col === undefined) return null

  if (col[0] === '#') {
    col = col.slice(1)
    usePound = true
  }

  var num = parseInt(col, 16)

  var r = (num >> 16) + amt

  if (r > 255) r = 255
  else if (r < 0) r = 0

  var b = ((num >> 8) & 0x000F) + amt

  if (b > 255) b = 255
  else if (b < 0) b = 0

  var g = (num & 0x0000FF) + amt

  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}


export const convertHexToRGBA = (hex) => {  
  var c;
  var hex_char = new RegExp("^#(?=.*[A-Fa-f0-9]{3}){1,2}");

  if (hex_char.test(hex)) {
    c = hex.substring(1).split('');

    if (c.length == 3){
      c =[c[0],c[0],c[1],c[1],c[2],c[2]];
    }
    c= '0x' +c.join('');
    return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.3)';
    throw new Error('Bad Hex')
    
  }
};