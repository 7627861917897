import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./pages";
import InvalidPage from "./pages/invalidPage";

function App() {
  
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/:ref" element={<LandingPage/>} />
          <Route path="*" element={<InvalidPage />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
