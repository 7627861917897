import { actionTypes } from "../../constants/actionTypes";

const initialState = {
    isLoading: false,
    error: null,
    banks: [],
}

export const bankReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_ALL_BANK_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.GET_ALL_BANK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                banks:payload
            };
        case actionTypes.GET_ALL_BANK_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            };
        default:
            return state;
    }
}

