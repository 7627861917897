import { actionTypes } from "../../constants/actionTypes"
import axios_instance from "../../../constants/Api"

export const getAllBankRequest = () => {
    return {
        type: actionTypes.GET_ALL_BANK_REQUEST,
    }
}

export const getAllBankSuccess = (banks) => {
    return {
        type: actionTypes.GET_ALL_BANK_SUCCESS,
        payload: banks
    }
}

export const getAllBankFailure = (error) => {
    return {
        type: actionTypes.GET_ALL_BANK_FAILURE,
        payload: error
    }
}

//get all Bank
export const getAllBanks = () => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(getAllBankRequest());

        // axios_instance.get('bank_statement/widget/bank/list', {
        axios_instance.get('identitypass/statement/sdk/banks/get', {
        }).then(function (response) {
            dispatch(getAllBankSuccess(response.data))
            resolve(response.data)
        }).catch((error) => {
            var error_message = ''
            if (error.response === undefined) {
                // network error
                error_message = error.message
            }
            dispatch(getAllBankFailure(error_message))
            reject(error)
        })
            .finally(function () {
                // always executed
            });;
    })
}

