import { actionTypes } from "../../constants/actionTypes"
import axios_instance from "../../../constants/Api"

export const configRequest = () => {
    return {
        type: actionTypes.CONFIG_REQUEST,
    }
}

export const getConfigSuccess = (data) => {
    return {
        type: actionTypes.GET_CONFIG_SUCCESS,
        payload: data
    }
}

export const configFailure = (error) => {
    return {
        type: actionTypes.CONFIG_FAILURE,
        payload: error
    }
}


// get Config
export const getConfig = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(configRequest());

        // axios_instance.post('bank_statement/widget/initializer/get', data, {
        axios_instance.post('identitypass/statement/sdk/session/get', data, {
        }).then(function (response) {
            dispatch(getConfigSuccess(response.data))
            resolve(response.data)
        }).catch((error) => {
            var error_message = ''
            if (error.response === undefined) {
                // network error
                error_message = error.message
            }
            dispatch(configFailure(error_message))
            reject(error)
            console.log(error);
        })
            .finally(function () {
                // always executed
            });;
    })
}
