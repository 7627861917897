import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBanks } from '../redux/actions/banks'
import FooterComp from './footer'
import { Mainloader } from './utils/loaders'

export default function BanksModal(props) {
    const [tab, setTab] = useState("personal")
    const [bankOverlay, setBankOverlay] = useState(false)
    const [bankInfo, setBankInfo] = useState({})
    const [searchInput, setSearchInput] = useState("")

    const bankState = useSelector(state => state.bankReducer)


    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllBanks())
    }, [dispatch])

    let openBankOverlay = (iconValue, colorValue, bankIdValue) => {
        var bankInfo = {
            bankColor: colorValue,
            bankIcon: iconValue,
            bankID: bankIdValue
        }
        setBankInfo(bankInfo)
        setBankOverlay(true)
    }

    let goToBank = (method) => {
        var finalBankInfo = {
            bankColor: bankInfo.bankColor,
            bankIcon: bankInfo.bankIcon,
            loginMethod: method,
            bankID: bankInfo.bankID
        }
        props.pushBankInfo(finalBankInfo)
        props.changePage(3)
    }

    const filteredBanks = bankState?.banks?.filter(bank => {
        return bank.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
    })

    return (
        <div className='banks-modal'>
            <div className="main-modal-content card col-md-7 col-lg-4 mx-auto">
                {bankState.isLoading ?
                    <Mainloader />
                    :
                    <>
                        <div className="main-modal-header pt-4 px-md-5 text-center">
                            <i className="ri-arrow-left-line " onClick={() => props.changePage(1)}></i>
                            <h5 className='p-0 m-0'>Choose your bank</h5>
                            {/* <i className="ri-close-line" onClick={props.setOpenComponent}></i> */}
                            <i className="ri-close-line" onClick={props.closeComponent} />
                        </div>
                        <div className="card-body  px-md-5">
                            <div className="search-input">
                                <i className="ri-search-line ri-xl me-2"></i>
                                <input type="search" name="" id="" className='form-control'
                                    onChange={search => setSearchInput(search.target.value)}
                                    placeholder="Search your bank..."
                                />
                            </div>
                            {/* <div className="tab-area my-4">
                                <h5 className={tab === "personal" ? "active" : ""} onClick={() => setTab("personal")}>Personal</h5>
                                <h5 className={tab === "business" ? "active" : ""} onClick={() => setTab("business")}>Business</h5>
                            </div> */}
                            <div className="main-modal-body">
                                <div className="bank-list">
                                    {filteredBanks?.map((bank, index) => (
                                        <div onClick={() => openBankOverlay(bank.icon, bank.color, bank.id)} key={index}>
                                            <img src={bank.icon} className="bank-logo" alt="" />
                                            <p>{bank.name}</p>
                                        </div>
                                    ))}
                                </div>

                                {/* {tab === "personal" &&
                                    <div className="bank-list">
                                        {filteredBanks?.map((bank, index) => {
                                            if (bank.type === "PERSONAL_BANKING") {
                                                return (
                                                    <div onClick={() => openBankOverlay(bank.icon, bank.color, bank.id)} key={index}>
                                                        <img src={bank.icon} className="bank-logo" alt="" />
                                                        <p>{bank.name}</p>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                }
                                {tab === "business" &&
                                    <div className="bank-list">
                                        {filteredBanks?.map((bank, index) => {
                                            // if (bank.type === "BUSINESS_BANKING") {
                                            if (bank.type === "COMMERCIAL") {
                                                return (
                                                    <div onClick={() => openBankOverlay(bank.icon, bank.color, bank.id)} key={index}>
                                                        <img src={bank.icon} className="bank-logo" alt="" />
                                                        <p>{bank.name}</p>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                } */}
                            </div>
                        </div>

                        {bankOverlay &&
                            <div className="bank-overlay">
                                <div className="card-body  px-md-5">
                                    <div className="main-modal-body">
                                        <div className='bank-overlay-header'>
                                            <img src={bankInfo?.bankIcon} className="bank-logo" alt="" />
                                            <i className="ri-close-line close-overlay " onClick={() => setBankOverlay(false)}></i>
                                        </div>
                                        <div className="text-center mt-3">
                                            <h5>Choose login method</h5>
                                        </div>

                                        <div className='overlay-option' onClick={() => goToBank("internet_banking")}>
                                            <i className="ri-computer-line" onClick={props.setOpenComponent}></i>
                                            <div>
                                                <h5 className='pt-1 m-0'>Login with Internet Banking</h5>
                                                <small className='m-0'>Sign in seamlessly with your internet banking credentials.</small>
                                            </div>
                                        </div>
                                        <div className='overlay-option' onClick={() => goToBank("mobile_banking")}>
                                            <i className="ri-smartphone-line" onClick={props.setOpenComponent}></i>
                                            <div>
                                                <h5 className=' pt-1 m-0'>Login with mobile Banking</h5>
                                                <small className='m-0'>Instantly sign in using your mobile banking information</small>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                        <FooterComp />
                    </>
                }
            </div>
        </div>
    )
}
