import Cookies from 'js-cookie'
import global from './global'

const axios = require('axios');
const tokens_res = Cookies.get('tokens') || ''
// const comingFrom = window.location.pathname

var token = ''
if (tokens_res !== '') {
  const tokens = JSON.parse(tokens_res)
  token = tokens
}
const apiBaseUrl = global.apiBaseUrl


var headers = {
  'x-api-key': `${process.env.REACT_APP_API_KEY}`,
}

const axios_instance = axios.create({
  baseURL: apiBaseUrl,
  timeout: 10000000,
  headers: token ? { 'Authorization': 'Bearer ' + token, 'x-api-key': `${process.env.REACT_APP_API_KEY}` } : headers
});

axios_instance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response !== undefined) {
    if (error.response.status === 401) {
      //place rentry location.....login
      Cookies.remove('authenticated');
      Cookies.remove('tokens');
      localStorage.removeItem('merchant');
      Cookies.remove('userDetails');
      
    }
  }
  return Promise.reject(error);
});

export default axios_instance
