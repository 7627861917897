import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import waveImg from '../assets/activeWave.png'
import { lightenDarkenColor, convertHexToRGBA } from './utils/changecolor'
import { useParams } from 'react-router-dom'
import { verifyLoginData } from '../redux/actions/login'
import toast from 'react-hot-toast';
import { Spinner } from 'react-bootstrap';
import successImg from '../assets/success.gif'
import FooterComp from './footer'

export default function ActiveBankModal(props) {
    const [tab, setTab] = useState("login")
    const [successPage, setSuccessPage] = useState(false)
    const [userId, setUserId] = useState('')
    const [responsePage, setResponsePage] = useState(0)
    const [password, setPassword] = useState("")
    const [answer, setAnswer] = useState("")
    const [listOrTodo, setListOrTodo] = useState([])
    const [sessionId, setSessionId] = useState("")

    const loginState = useSelector(state => state.loginReducer)

    const dispatch = useDispatch()
    let { ref } = useParams();

    const lightestColor = lightenDarkenColor(props.bankInfo.bankColor, 240)
    const lightColor = lightenDarkenColor(props.bankInfo.bankColor, 70)

    const lightestrgba = convertHexToRGBA(lightestColor)
    const lightrgba = convertHexToRGBA(lightColor)

    let activeTab = {
        backgroundColor: props.bankInfo.bankColor,
        color: lightestColor,
        padding: '15px 10px'
    }

    let inactiveTab = {
        color: "#000",
        backgroundColor: lightestColor,
    }


    let encryptData = function encryptMessage(message, publicKey) {
        // eslint-disable-next-line no-undef
        const jsEncrypt = new JSEncrypt();
        jsEncrypt.setPublicKey(publicKey);

        return jsEncrypt.encrypt(message);
    }

    let submitloginData = () => {
        if (!userId) {
            toast.error("Please input your userID")
        }
        else if (!password) {
            toast.error("Please input password")
        }
        else {
            let loginInfo = {
                "username": userId,
                "password": password,
                // "auth_method": props.bankInfo.loginMethod,
                // "institution": props.bankInfo.bankID
            }
            // encryptData(loginInfo, process.env.RSA_PUBLIC_KEY)

            // var encryptedMessage = encryptData(JSON.stringify(loginInfo), process.env.REACT_APP_RSA_PUBLIC_KEY)
            // let data = {
            //     token: ref,
            //     auth_data: encryptedMessage,
            //     // auth_data:"EQLAGp4s52bwTu1QeKt88hOXMRaTELhAOLNxhH9HiDINHIFNwxzJ1+apFj/Edb1HglUsnjDn1yU61Ti6aPaL9BC46Sk/pFc5FJTggUre1gXSJE008K5pNXuF2Vp0l05RGnirxW+OQeFNT5qBohRZjBrQD4MPU+pwmiggWRdzL90dEIMqEZCR5Vya4ih4Pc2Xz8k+kKYQ0x/je99QAOzYMVpTI7lvwgPBNSt32L0f/JOXG7hOYNCxz91j36o13ryjPKfwBbl9helvXpG3GJ5HWDhDcXK8+m3NKady/ce01MAbpV22ksKbkU3ZxAxYkTd+IHtixHz/+naMPtUhaiKf5Q==",
            //     mode:"STANDARD",
            // }
            dispatch(verifyLoginData(loginInfo)).then((resp) => {
                setSessionId()
                setSuccessPage(true)
            }).catch((error) => {
                // if (error?.response?.data?.response_code === "06") {
                //     setListOrTodo(error?.response?.data?.accounts)
                //     console.log(error?.response?.data?.session_id)
                //     setSessionId(error?.response?.data?.session_id)
                //     setResponsePage(1)
                // }
                // else if (error?.response?.data?.response_code === "07"){
                //     setListOrTodo(error?.response?.data?.accounts?.form)
                //     setSessionId(error?.response?.data?.session_id)
                //     setResponsePage(2)
                // }
                // else{
                // toast.error(error?.response?.data?.message)
                toast.error(error?.response?.data?.detail)
                // }
            })
        }
    }

    let submitLoginAccount = (accountNumber) => {
        let loginInfo = {
            "username": userId,
            "password": password,
            "auth_method": props.bankInfo.loginMethod,
            "institution": props.bankInfo.bankID,
            "account": accountNumber,
            "session_id": sessionId
        }
        encryptData(loginInfo, process.env.RSA_PUBLIC_KEY)

        var encryptedMessage = encryptData(JSON.stringify(loginInfo), process.env.REACT_APP_RSA_PUBLIC_KEY)
        let data = {
            token: ref,
            auth_data: encryptedMessage,
            mode: "COMMIT",
        }
        dispatch(verifyLoginData(data)).then(() => {
            setSuccessPage(true)
        }).catch((error) => {
            if (error?.response?.data?.response_code === "07") {
                setListOrTodo(error?.response?.data?.accounts?.form)
                setSessionId(error?.response?.data?.session_id)
                setResponsePage(2)
            }
            else {
                toast.error(error?.response?.data?.message)
            }
        })
    }

    let submitQuestionData = () => {
        if (!answer) {
            toast.error("Please input all fields")
        }
        else {
            let loginInfo = {
                "username": userId,
                "password": password,
                "auth_method": props.bankInfo.loginMethod,
                "institution": props.bankInfo.bankID,
                "answer": answer,
                "session_id": sessionId
            }
            encryptData(loginInfo, process.env.RSA_PUBLIC_KEY)

            var encryptedMessage = encryptData(JSON.stringify(loginInfo), process.env.REACT_APP_RSA_PUBLIC_KEY)
            let data = {
                token: ref,
                auth_data: encryptedMessage,
                mode: "COMMIT",
            }
            dispatch(verifyLoginData(data)).then(() => {
                setSuccessPage(true)
            }).catch((error) => {
                toast.error(error?.response?.data?.message)
            })
        }
    }

    let dispatchSuccess = () => {

        // dispatch success event for parent element
        var event_response = {
            eventInstance: 'identity_bank_event',
            data: {
                status: 'success',
                message: 'Statement Retrieved successfully',
            }
        }
        window.parent.postMessage(event_response, '*')
    }


    return (
        <>
            {!successPage ?
                <div className='privacy-modal active-bank-modal'>
                    <div className="main-modal-content card col-md-7 col-lg-4 mx-auto">
                        <span><i className="ri-close-line close-modal" onClick={props.closeComponent}></i></span>
                        <div className="main-modal-header pt-5 px-3 px-md-5 text-center"
                            style={{
                                background: `linear-gradient(${lightrgba}, ${lightestrgba}, ${lightrgba}, ${props.bankInfo.bankColor}), url('${waveImg}')`
                            }}
                        >
                            <img src={props.bankInfo.bankIcon} className="bank-logo mx-auto mb-4" alt="" />
                            <div className="tab-area my-4">
                                <h5 style={tab === "login" ? activeTab : inactiveTab} onClick={() => setTab("login")}>Log In</h5>
                                <h5 style={tab === "personal" ? activeTab : inactiveTab} onClick={() => setTab("personal")}>Personal Account</h5>
                            </div>
                        </div>

                        <div className="card-body  px-md-5">
                            <div className="main-modal-body my-1">
                                <div className="login-inputs">
                                    <input type="text" className='form-control mt-3'
                                        value={userId}
                                        onChange={userId => setUserId(userId.target.value)}
                                        style={{ border: `1px solid ${props.bankInfo.bankColor}`, backgroundColor: lightestColor }}
                                        placeholder="userID"
                                    />
                                    <input type="password" className='form-control mt-4'
                                        value={password}
                                        onChange={password => setPassword(password.target.value)}
                                        style={{ border: `1px solid ${props.bankInfo.bankColor}`, backgroundColor: lightestColor }}
                                        placeholder="Password"
                                    />
                                </div>

                                <button className='btn w-100 py-3'
                                    onClick={submitloginData}
                                    style={{ background: props.bankInfo.bankColor, color: lightestColor, marginTop: "150px" }}
                                >
                                    {loginState.isLoading
                                        ?
                                        <div>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        "Login"
                                    }
                                </button>
                            </div>
                        </div>

                        {responsePage === 1 &&
                            <div className="bank-overlay">
                                <div className="card-body  px-md-5">
                                    <div className="main-modal-body">
                                        <div className='bank-overlay-header'>
                                            <div className="mt-2">
                                                <h5>Accounts</h5>
                                                <p>Select Login Account</p>
                                            </div>
                                            <i className="ri-close-line close-overlay " onClick={() => setResponsePage(0)}></i>
                                        </div>

                                        {loginState.isLoading
                                            ?
                                            <div className='text-center my-5' >
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="md"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <p className="sr-only">Loading...</p>
                                            </div>
                                            :
                                            <>
                                                {listOrTodo?.map((data, index) => (
                                                    <div className='overlay-option justify-content-between' key={index} onClick={() => submitLoginAccount(index)}>
                                                        <div className='d-flex'>
                                                            <i className="ri-user-line"></i>
                                                            <div>
                                                                <h5 className='pt-1 m-0' style={{ fontSize: "13.5px" }}>{data?.name}</h5>
                                                                <small className='m-0'>{data?.accountNumber}</small>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className='m-0 py-1 px-2' style={{ background: props.bankInfo.bankColor, color: lightestColor, borderRadius: "15px", fontSize: "13px" }}>
                                                                {`${data.currency}`}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        }

                        {responsePage === 2 &&
                            <div className="bank-overlay">
                                <div className="card-body  px-md-5">
                                    <div className="main-modal-body">
                                        <div className='bank-overlay-header'>
                                            <div className="mt-2">
                                                <h5>Security Question</h5>
                                                <p>Please respond to your security question</p>
                                            </div>
                                            <i className="ri-close-line close-overlay " onClick={() => setResponsePage(0)}></i>
                                        </div>
                                        <div className='overlay-option sec-question'>
                                            <div>
                                                <div className="login-inputs">

                                                    {listOrTodo?.map((data, index) => (
                                                        <div key={index} >
                                                            {data.name === "answer" ?
                                                                <>
                                                                    <h6 className='m-0'>{data?.hint}</h6>
                                                                    <textarea className='form-control mt-1'
                                                                        value={answer}
                                                                        onChange={answer => setAnswer(answer.target.value)}
                                                                        style={{ border: `1px solid ${props.bankInfo.bankColor}`, backgroundColor: lightestColor }}
                                                                        rows="3" placeholder='Type your Answer here'
                                                                    ></textarea>
                                                                </>
                                                                :
                                                                <>
                                                                    <h6 className='m-0'>Enter OTP</h6>
                                                                    <input type="password" className='form-control'
                                                                        value={answer}
                                                                        onChange={answer => setAnswer(answer.target.value)}
                                                                        style={{ border: `1px solid ${props.bankInfo.bankColor}`, backgroundColor: lightestColor }}
                                                                        placeholder="123456"
                                                                    />
                                                                </>
                                                            }
                                                        </div>
                                                    ))}

                                                </div>

                                                <button className='btn w-100 py-3'
                                                    onClick={submitQuestionData}
                                                    style={{ background: props.bankInfo.bankColor, color: lightestColor, marginTop: "100px" }}
                                                >
                                                    {loginState.isLoading
                                                        ?
                                                        <div>
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                        :
                                                        "Login"
                                                    }
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                        <FooterComp />
                    </div>
                </div>
                :
                <div className='privacy-modal active-bank-modal'>
                    <div className="main-modal-content card col-md-7 col-lg-4 mx-auto">
                        <span><i className="ri-close-line close-modal" onClick={props.closeComponent}></i></span>
                        <div className="card-body  px-md-5">
                            <div className="main-modal-body my-4 text-center">
                                <img src={successImg} alt="" className='mt-5 success-gif' />

                                <h4 className='mt-5' >Successful</h4>
                                <p>Your data has been successfully received. Kindly click on the "done" button</p>

                                <button className='btn w-100 py-3 btn-blue'
                                    onClick={dispatchSuccess}
                                    style={{ marginTop: "100px" }}
                                >
                                    I'm done
                                </button>
                            </div>
                        </div>
                        <FooterComp />
                    </div>
                </div>
            }
        </>
    )
}
