import { actionTypes } from "../../constants/actionTypes"
import axios_instance from "../../../constants/Api"

export const loginRequest = () => {
    return {
        type: actionTypes.LOGIN_REQUEST,
    }
}

export const loginSuccess = (data) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        payload: data
    }
}

export const loginFailure = (error) => {
    return {
        type: actionTypes.LOGIN_FAILURE,
        payload: error
    }
}


// verify Info
export const verifyLoginData = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(loginRequest());

        // axios_instance.post('bank_statement/widget/verify', data, {
        axios_instance.post('identitypass/statement/sdk/account/verify', data, {
        }).then(function (response) {
            dispatch(loginSuccess(response.data))
            resolve(response.data)
        }).catch((error) => {
            var error_message = ''
            if (error.response === undefined) {
                // network error
                error_message = error.message
            }
            dispatch(loginFailure(error_message))
            reject(error)
            console.log(error);
        })
            .finally(function () {
                // always executed
            });;
    })
}
