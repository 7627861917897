import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ParentModal from '../components/parentModal'
import { Mainloader } from '../components/utils/loaders';
import { getConfig } from '../redux/actions/config';
import InvalidPage from './invalidPage';

export default function LandingPage() {
    const [openComponent, setOpenComponent] = useState(false)
    const [failedPage, setFailedPage] = useState(false)

    const configState = useSelector(state => state.configReducer)

    const dispatch = useDispatch()
    let { ref } = useParams();

    useEffect(() => {
        let data ={
            token: ref
        }
        dispatch(getConfig(data)).then(()=>{
            setOpenComponent(true)
            setFailedPage(false)
        }).catch(()=>{
            setFailedPage(true)
        })
    }, [dispatch])
    

    return (
        <div>
            {configState?.isLoading ?
                <Mainloader/>
                :
                <>
                    {failedPage ? 
                        <InvalidPage/>
                        :
                        <ParentModal openComponent={openComponent} setOpenComponent={() => setOpenComponent(!openComponent)} />
                    }
                </>
            }
        </div>
    )
}
