import React from 'react'
import FooterComp from './footer'

export default function PrivacyModal(props) {
    return (
        <div className='privacy-modal'>
            <div className="main-modal-content card col-md-7 col-lg-4 mx-auto">
                <span><i className="ri-close-line close-modal" onClick={props.closeComponent} ></i></span>
                <div className="main-modal-header pt-5 px-3 px-md-5">
                    <h2>Privacy Guaranteed.</h2>
                    <p>
                        Any data you voluntarily provide to us upon signup is strictly protected by data 
                        privacy policies and will not be shared with any third party
                    </p>
                </div>
                <div className="card-body  px-md-5">
                    <div className="main-modal-body">
                        <div className="spec-area">
                            <i className="ri-lock-line" />
                            <div>
                                <h4 className='pt-1 m-0'>Secured</h4>
                                <small className='m-0'> Your bank information is encrypted using our platform </small>
                            </div>
                        </div>
                        <div className="spec-area">
                            <i className="ri-eye-off-line" />
                            <div>
                                <h4 className='pt-1 m-0'>Private</h4>
                                <small className='m-0'> Your credentials will not be exposed or accessible</small>
                            </div>
                        </div>
                        <div className="spec-area">
                            <i className="ri-shield-keyhole-line" />
                            <div>
                                <h4 className='pt-1 m-0'>Protected</h4>
                                <small className='m-0'> Your account is protected and no one has the right to move your funds</small>
                            </div>
                        </div>

                        <div className='text-center'>
                            <button className='btn btn-blue w-100 py-3 mt-5 mb-3' onClick={()=>props.changePage(2)}> Agree & Proceed</button>
                            <FooterComp/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
