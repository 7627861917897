import { actionTypes } from "../../constants/actionTypes";

const initialState = {
    isLoading: false,
    error: null,
    login:null
}

export const loginReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.LOGIN_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                login: payload
            };
        case actionTypes.LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            };
        default:
            return state;
    }
}

