import React from 'react'
import ContentLoader, { Code, List } from 'react-content-loader'

export function Mainloader() {
	return (
		<>
			<Code />
			<Code height={"140"} />
			<List height={"160"} />
			<List height={"160"} />
			<Code height={"140"} />
		</>
	)
}
